//default
$white: #fff;
$black: #000;
$grey-color: #c4c4c4;
$text: #8890a9;

//main colors
$main-blue: #6b8fff;
$medium-blue: #9ab3ff;
$small-text: #484848;
$main-green: #52c58d;
$light-green: #5ece98;
$medium-green: #5ece92;
$light-red: #ff9198;
$text-zagolovki: #535d8a;
$light-text: #afb6ce;
$semi-title-darken: #434343;

//colors
$blue-bg: #ecf0fe;
$light-blue-bg: #fafbff;
$clinic-bg: #54c0ff;
$technology-bg: #8570ff;
$medium-clinic-bg: #11a7ff;
$transparent-blue: #a1b8ff;
$progress-bar-bg: #e7eaf1;
$show-more-text: #4f5464;
$semi-transparent-blue: #f1f4ff;
$light-blue-text: #7f8bc0;
$search-text-tree: #7d86a0;
$blue-result: #708adb;
$darken-text: #606370;
$tag-color: #8d8d8d;
$accent-tag-color: #7080b7;
$accent-orange: #ff9100;
$table-border-color: #ebebf1;
$table-background-color: #fafafa;

//tags colors
$all-tag: #6b8fff;
$treatments-tag: #52c58d;
$specialists-tag: #6b8fff;
$clinic-tag: #54c0ff;
$technologies-tag: #8570ff;

//gradient scrolling
$gradient-scrolling-white: linear-gradient(
  to left,
  #fff 0,
  #fff 33%,
  rgba(255, 255, 255, 0.7) 66%,
  rgba(255, 255, 255, 0) 100%
);

//main font
$main-font: 'Roboto';

//rating
$rating: #ffba33;

//forms
$forms: #d7dbe7;
$radio-color: #595c69;
$input: #bec4d9;
$error: #e94148;
$medium-error: #ff464e;
$button-bg: #f6fcf9;

//box shadows
$box-shadow: 5px 5px 15px rgba($main-blue, 0.2);
$box-shadow-active: 5px 5px 15px rgba($main-blue, 0.3);
$box-shadow-highest: 0 0 10px rgba($main-blue, 0.1);
$box-shadow-highest-active: 0 0 10px rgba($main-blue, 0.2);
$box-shadow-header: 2px 2px 5px rgba($main-blue, 0.2);
$box-shadow-header-fixed: 2px 2px 20px rgba($main-blue, 0.5);
$top-wrapper-shadow: 2px 2px 5px rgba($main-blue, 0.2);
$box-shadow-clinic-button-wrapper: 2px 2px 20px rgba($main-blue, 0.2);
$box-shadow-mob-menu: 2px 2px 5px rgba(111, 111, 111, 0.2);
$box-shadow-clinic-card-active: 5px 5px 20px rgba(50, 79, 170, 0.2);
$medium-shadow-card: 10px 10px 20px rgba(0, 0, 0, 0.15);
$medium-shadow-card-active: 10px 10px 20px rgba(0, 0, 0, 0.35);
$large-shadow-card: 10px 10px 30px rgba(50, 79, 170, 0.2);
$attention-box-shadow: 10px 10px 20px rgba(107, 143, 255, 0.2);

//header
$height-header: 55px;
$height-block-header: 52px;
$width-block-header: 50%;
$header-text: #606060;

//cards
$width-card: 310px;
$width-card-map: 155px;
$height-card-medium: 155px;
$height-card-small: 110px;
$cards-bg: #f2f2fd;
$clinic-cards-bg: #b9caff;
$border-map-card: #54c0ff;
$text-map-card: #363a47;

//octagon
$octagon-back-color: #5c6c9e;
$octagon-links-color: #6775a7;

//img
$img-bg: #efeffc;

//borders
$border-green: #a4e0c4;
$light-border-color: #e6eaf7;
$light-blue-border: #e0e5fa;

//sidebar
$width-sidebar: 280px;
$width-left-sidebar: 90px;

//icons
$icon-size: 13px;

//modal
$modal-bg: rgba(136, 144, 169, 0.4);

//scrollbar
$scrollbar-bg: rgba(154, 179, 255, 0.15);
$scroll-bg: #f1f1fe;

//selections
$selection-color: #fff;
$selection-bg: #9ab3ff;

//slider
$dots-color: #d0d0d0;
$dots-color-active: #6b8fff;
