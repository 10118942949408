@import '../../../../assets/css/variable';
@import '../../../../assets/css/mixins';

.popularServices {
  background: url(../../../../assets/img/services/bg-services-xxl.jpg) no-repeat center center;
  background-size: cover !important;
  padding: 72px 0;
  position: relative;

  @media screen and (max-width: 1499.98px) {
    padding: 65px 0;
    background: url(../../../../assets/img/services/bg-services-xl.jpg) no-repeat center center;
  }

  @media screen and (max-width: 1199.98px) {
    padding: 45px 0;
    background: url(../../../../assets/img/services/bg-services-lg.jpg) no-repeat center center;
  }

  @media screen and (max-width: 991.98px) {
    padding: 50px 0;
    background: url(../../../../assets/img/services/bg-services-md.jpg) no-repeat center center;
  }

  @media screen and (max-width: 767.98px) {
    padding: 40px 0;
  }

  @media screen and (max-width: 575.98px) {
    background: url(../../../../assets/img/services/bg-services-xs.jpg) no-repeat center center;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
  }

  &::before {
    background-color: rgba($light-blue-bg, 0.95);
    width: 44.5%;
    left: 0;

    @media screen and (max-width: 1199.98px) {
      width: 35.7%;
    }

    @media screen and (max-width: 991.98px) {
      width: 100%;
      top: 0;
      height: 56%;
    }

    @media screen and (max-width: 575.98px) {
      display: none;
    }
  }

  &::after {
    background-color: rgba($main-blue, 0.85);
    width: 55.5%;
    right: 0;
    left: auto;

    @media screen and (max-width: 1199.98px) {
      width: 64.3%;
    }

    @media screen and (max-width: 991.98px) {
      width: 100%;
      height: 44%;
      top: auto;
      bottom: 0;
    }

    @media screen and (max-width: 575.98px) {
      height: 100%;
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    @media screen and (min-width: 992px) {
      max-width: 975px;
    }

    @media screen and (min-width: 1200px) {
      max-width: 975px;
    }

    @media screen and (min-width: 1500px) {
      max-width: 1115px;
    }

    @media screen and (max-width: 991.98px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .wrapperContent {
    margin: 0 0 20px 0;

    @media screen and (min-width: 768px) {
      max-width: 250px;
    }

    @media screen and (min-width: 1200px) {
      max-width: 315px;
      margin: 0 30px 35px 8px;
    }

    @media screen and (min-width: 1500px) {
      margin: 0 30px 40px 0;
    }

    @media screen and (max-width: 991.98px) {
      width: 100%;
      text-align: center;
      max-width: 385px;
    }

    @media screen and (max-width: 575.98px) {
      margin: 0 0 15px 0;
    }

    .semiTitle {
      @media (min-width: 1200px) and (max-width: 1499.98px) {
        margin-bottom: 15px;
      }

      @media screen and (max-width: 575.98px) {
        color: $white;
      }
    }
  }

  .subInfo {
    margin-bottom: 20px;

    @media screen and (max-width: 991.98px) {
      max-width: 420px;
      margin: 0 auto 15px;
    }

    @media screen and (max-width: 575.98px) {
      color: $white;
      max-width: 320px;
    }
  }

  .button {
    button {
      min-width: 192px;
      height: 40px;
      justify-content: center;

      @media screen and (max-width: 1499.98px) {
        height: 35px;
        font-size: 13px;
        min-width: 159px;
      }

      @media screen and (max-width: 767.98px) {
        height: 30px;
        font-size: 12px;
        min-width: 120px;
      }

      @media screen and (max-width: 575.98px) {
        background: $white;
        color: $main-blue;

        &:hover {
          background: $white;
          color: $main-blue;
        }

        &:active {
          background: darken($white, 5);
          color: $main-blue;
          box-shadow: 0 0 0 3px rgba($white, 0.5);
        }

        &:focus {
          background: darken($white, 5);
          color: $main-blue;
          box-shadow: 0 0 0 3px rgba($white, 0.5);
        }
      }
    }
  }

  .wrapperCards {
    display: flex;
    align-items: center;
    margin-top: -10px;

    @media screen and (min-width: 1500px) {
      margin-right: 10px;
    }

    @media screen and (max-width: 1199.98px) {
      margin-right: 38px;
    }

    @media screen and (max-width: 991.98px) {
      flex: 1;
      max-width: 675px;
      margin: 5px auto 0;
    }

    @media screen and (max-width: 575.98px) {
      max-width: 280px;
      flex-direction: column;
    }

    .wrapperCardsTwo {
      display: flex;

      @media screen and (min-width: 992px) {
        flex-direction: column;
        align-items: flex-end;
        margin: 0 15px;
      }

      @media screen and (max-width: 991.98px) {
        margin: 0;
        flex: 0 0 calc(100% - 33.3%);
      }

      @media screen and (max-width: 575.98px) {
        flex: 1;
        flex-direction: column;
        width: 100%;
      }
    }

    .cardComingSoon {
      background: $white;
      box-shadow: $medium-shadow-card;
      border-radius: 20px;
      overflow: hidden;
      padding: 35px 10px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      user-select: none;
      @include transition-time;

      @media screen and (max-width: 991.98px) {
        flex: 1;
        padding: 31px;
        margin: 7.5px;
      }

      @media screen and (max-width: 575.98px) {
        width: 100%;
        margin: 7.5px 0;
      }

      &:hover {
        transform: translateY(-5px);
        box-shadow: $medium-shadow-card-active;
      }

      &:active {
        transform: scale(0.97);
      }

      &.verticalCard {
        @media screen and (min-width: 992px) {
          width: 200px;
          margin-bottom: 15px;
        }

        @media screen and (min-width: 1500px) {
          width: 210px;
        }
      }

      &.verticalCardLarge {
        @media screen and (min-width: 992px) {
          margin-top: 21px;
          width: 215px;
          padding: 50px 10px 27px;
        }

        @media screen and (min-width: 1500px) {
          width: 225px;
          padding: 50px 10px 32px;
        }
      }

      &.horizontalCard {
        @media screen and (min-width: 992px) {
          flex-direction: row;
          justify-content: space-between;
          width: 270px;
          padding: 40px 32px 25px 55px;
        }

        @media screen and (min-width: 1200px) {
          padding: 40px 42px 25px 55px;
        }

        @media screen and (min-width: 1500px) {
          width: 280px;
          padding: 39px 42px 39px 55px;
        }

        @media screen and (max-width: 991.98px) {
          flex-direction: column-reverse;
        }
      }

      svg {
        @media screen and (max-width: 991.98px) {
          max-width: 90px;
          max-height: 90px;
        }
      }
    }

    .verticalCardIcon {
      margin-bottom: 36px;

      @media screen and (max-width: 1499.98px) {
        margin-bottom: 27px;
      }
    }

    .verticalCardLargeIcon {
      margin-bottom: 48px;

      @media screen and (max-width: 1499.98px) {
        margin-bottom: 33px;
      }
    }

    .cardComingSoonIcon {
      @media screen and (max-width: 991.98px) {
        margin: 0 0 19px 0;
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .cardName {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.2;
      color: $black;
      letter-spacing: 0.03em;
      margin-bottom: 8px;
    }

    .cardChoose {
      font-size: 12px;
      line-height: 1.2;
      letter-spacing: 0.05em;
      color: $light-text;
      position: relative;
      padding-left: 22px;

      &::before {
        content: '';
        background: url(../../../../assets/img/icon/choose-arrow-icon.svg) no-repeat center center;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        height: 10px;
        width: 14px;
        @include transition-time;
      }
    }
  }
}
