@import '../../../../assets/css/variable';
@import '../../../../assets/css/mixins';

.comingSoon {
  max-width: 1920px;
  margin: 0 auto;

  .item {
    display: flex !important;
    align-items: center;
    position: relative;
    min-height: 610px;
    background-size: cover !important;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 47%;
      background: rgba($main-blue, 0.7);

      @media screen and (max-width: 1499.98px) {
        width: 44%;
      }

      @media screen and (max-width: 1199.98px) {
        width: 49%;
      }

      @media screen and (max-width: 991.98px) {
        width: 51.5%;
      }

      @media screen and (max-width: 767.98px) {
        width: 100%;
      }
    }

    @media screen and (min-width: 576px) {
      min-height: 550px;
    }

    @media screen and (min-width: 768px) {
      min-height: 640px;
    }

    @media screen and (min-width: 1200px) {
      min-height: 650px;
    }

    @media screen and (min-width: 1500px) {
      min-height: 860px;
    }

    &.first {
      background: url(../../../../assets/img/coming-soon/first-screen-banner.jpg) no-repeat center center;
    }

    &.second {
      background: url(../../../../assets/img/coming-soon/second-screen-banner.jpg) no-repeat center center;
    }

    &.third {
      background: url(../../../../assets/img/coming-soon/third-screen-banner.jpg) no-repeat center center;
    }
  }

  .comingSoonContent {
    position: relative;
    z-index: 1;
    margin: 10px 0 78px 91px;

    @media screen and (max-width: 1499.98px) {
      margin: 10px 0 15px 70px;
    }

    @media screen and (max-width: 1199.98px) {
      margin: 10px 0 15px 155px;
    }

    @media screen and (max-width: 991.98px) {
      margin: 10px 0 15px 60px;
    }

    @media screen and (max-width: 767.98px) {
      margin: 10px 15px 15px 28px;
    }
  }

  .comingSoonTitle {
    font-weight: bold;
    font-size: 60px;
    line-height: 1.2;
    color: $white;
    max-width: 520px;
    margin-bottom: 20px;

    @media screen and (max-width: 1499.98px) {
      font-size: 48px;
      max-width: 410px;
      margin-bottom: 13px;
    }

    @media screen and (max-width: 1199.98px) {
      font-size: 32px;
      max-width: 280px;
      margin-bottom: 18px;
    }

    @media screen and (max-width: 767.98px) {
      font-size: 28px;
      max-width: 250px;
      margin-bottom: 7px;
    }
  }

  .comingSoonText {
    font-weight: normal;
    font-size: 14px;
    line-height: 1.43;
    color: $img-bg;
    max-width: 437px;
    margin-bottom: 28px;

    @media screen and (max-width: 1499.98px) {
      max-width: 395px;
    }

    @media screen and (max-width: 1199.98px) {
      max-width: 265px;
      margin-bottom: 16px;
    }

    @media screen and (max-width: 767.98px) {
      font-size: 12px;
      margin-bottom: 20px;
    }
  }
}
