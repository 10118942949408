@import '../../../../assets/css/variable';
@import '../../../../assets/css/mixins';

.advantages {
  background: $white;
  padding: 90px 0 99px;

  @media screen and (max-width: 1499.98px) {
    padding: 75px 0 70px;
  }

  @media screen and (max-width: 1199.98px) {
    padding: 60px 0 70px;
  }

  @media screen and (max-width: 991.98px) {
    padding: 55px 0 70px;
  }

  @media screen and (max-width: 767.98px) {
    padding: 40px 0 70px;
  }
}

.wrapper {
  @media screen and (min-width: 1200px) {
    max-width: 995px;
  }

  @media screen and (min-width: 1500px) {
    max-width: 1200px;
  }
}

.wrapperTitle {
  margin-bottom: 56px;

  @media screen and (max-width: 1499.98px) {
    margin-bottom: 67px;
  }

  @media screen and (max-width: 1199.98px) {
    margin-bottom: 35px;
  }

  @media screen and (max-width: 767.98px) {
    margin-bottom: 30px;
  }
}

.subInfo {
  max-width: 567px;
  margin: 0 auto;

  @media screen and (max-width: 1199.98px) {
    max-width: 550px;
  }

  @media screen and (max-width: 767.98px) {
    max-width: 100%;
    margin: 0 10px;
  }
}

.advantagesCards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px 25px;

  @media screen and (max-width: 1499.98px) {
    grid-gap: 56px 30px;
  }

  @media screen and (max-width: 1199.98px) {
    grid-gap: 36px 30px;
  }

  @media screen and (max-width: 991.98px) {
    grid-gap: 30px 25px;
  }

  @media screen and (max-width: 767.98px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px 0;
  }
}

.advantagesCard {
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  &:hover {
    .advantagesIcon {
      svg {
        transform: translateY(-5px) scale(1.05);
      }
    }
    .pathSvg {
      fill: rgba($main-blue, 0.65);
    }

    .advantagesCardName {
      color: $main-blue;
    }

    .advantagesCardInfo {
      color: $light-blue-text;
    }
  }
}

.advantagesIcon {
  margin-right: 25px;
  height: 70px;
  width: 80px;
  flex: 0 0 80px;

  @media screen and (max-width: 1499.98px) {
    margin-right: 30px;
    height: 60px;
    width: 70px;
    flex: 0 0 70px;
  }

  @media screen and (max-width: 1199.98px) {
    height: 50px;
    width: 55px;
    flex: 0 0 55px;
  }

  @media screen and (max-width: 991.98px) {
    margin-right: 25px;
  }

  svg {
    width: 100%;
    height: 100%;
    @include transition-time-fastest;
  }

  .pathSvg {
    fill: $main-blue;
    @include transition-time-fastest;
  }
}

.advantagesCardName {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.1;
  margin-bottom: 16px;
  color: $text-zagolovki;
  @include transition-time-fastest;

  @media screen and (max-width: 1499.98px) {
    font-size: 17px;
  }

  @media screen and (max-width: 1199.98px) {
    font-size: 15px;
    line-height: 1.2;
  }

  @media screen and (max-width: 767.98px) {
    margin-bottom: 13px;
  }
}

.advantagesCardInfo {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.43;
  color: $text;

  @media screen and (max-width: 1199.98px) {
    font-size: 13px;
  }

  @media screen and (max-width: 767.98px) {
    font-size: 11px;
    line-height: 14px;
  }
}
